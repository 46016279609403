<template>
  <div class="conference">
    <ConferenceWindow v-if="$store.state.connectionStatus === 'connected'" :conference="$store.state.conference" />
    <p v-else-if="$store.state.connectionStatus === 'connecting'" class="pa-8">
      Verbindung wird aufgebaut...
    </p>
    <div v-else class="pa-8 red--text">
      <p>
        Du bist mit keinem Meeting verbunden!
      </p>
      <v-btn
        @click="goToLogin()"
        class="pa-6 mb-6 mr-6"
        color="#19A229"
        dark>
        Verbinden
      </v-btn>
    </div>
  </div>
</template>
<script>

import ConferenceWindow from '@/components/ConferenceWindow.vue'

export default {
  name: 'Conference',
  components: {
    ConferenceWindow
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>
