<template>
  <div class="conference" id="conference">
    <iframe
      v-if="conference && conference.link"
      allow="camera; microphone; display-capture"
      :src="conference.link"
      allowfullscreen="true"
      width="100%"
      height="100%"></iframe>
    <p v-else-if="!conference || !conference.meetingId" class="pa-8">
      Es gibt keine aktive Konferenz.
    </p>
    <p v-else class="pa-8 notice">
      Verbindung wird aufgebaut...
    </p>
  </div>
</template>
<script>

export default {
  name: 'ConferenceWindow',
  props: {
    conference: Object
  },
  state: {
    api: Object
  },
  data: () => ({
    api: null
  }),
  methods: {
    close () {
      document.querySelector('#conference').innerHTML = ''
      this.api = null
    },
    open (meetingId) {
      const domain = 'konferenz.netzbegruenung.de'
      const options = {
        roomName: meetingId,
        width: '100%',
        height: '100%',
        parentNode: document.querySelector('#conference'),
        userInfo: {
          displayName: 'Präsidium'
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          disableSelfView: true,
          disableModeratorIndicator: true,
          hideDisplayName: true,
          hideDominantSpeakerBadge: true
        }
      }
      // eslint-disable-next-line
      this.api = new JitsiMeetExternalAPI(domain, options)
    }
  },
  watch: {
    conference: function (newValue, oldValue) {
      const currentId = newValue ? newValue.meetingId : null
      const oldId = oldValue ? oldValue.meetingId : null

      if ((currentId && currentId !== oldId && this.api) || (!currentId && this.api)) {
        this.close()
      }

      if (currentId && !this.api) {
        this.open(currentId)
      }
    }
  }
}
</script>

<style lang="scss">
  .conference {
    height: 100%;
  }

  .notice {
    position: absolute;
    z-index: -1;
  }
</style>
